import Cookies from 'universal-cookie';
import { Context } from "../globals/Context";
import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import { useContext, useEffect, useState, useMemo } from "react";
import { Link } from 'react-router-dom';



export default function Info() {
    const [isLoading, setIsLoading] = useState(true);
    const { isLoggedIn, setLoggedIn } = useContext(Context);
    const cookies = useMemo(() => new Cookies(), []);  // `cookies` is now memoized

    useEffect(() => {
        const pwc = cookies.get('pwc');
        if (pwc) {
            const bytes = AES.decrypt(pwc, process.env.REACT_APP_SECRET ? process.env.REACT_APP_SECRET : "fail");
            const decryptedPassword = bytes.toString(enc.Utf8);
            if (decryptedPassword === process.env.REACT_APP_PASSWORD) {
                setLoggedIn(true);
            }
        }
        setIsLoading(false);
    }, [cookies, isLoggedIn, setLoggedIn]);


    if (isLoggedIn) {
        return (
            <div className="content">
                {isLoading && <img src="loading.gif" alt="loading" />}
                {!isLoading && 
                    <div className="centered">
                        <p className="black-background">
                            Friday 13th September
                        </p>
                        <p className="black-background">
                            Blink is a non-profit party. We don’t make any money from this but need to cover costs.
                            If you can, we ask you to pay a participation fee to our mobilepay box 5749MA when you come to the party.
                        </p>
                        <p className="black-background">
                            There will be a bar offering some basic drinks, but feel free to bring your own.
                        </p>
                        <p className="black-background">
                            The music starts at 10 — we will serve a little treat to the first arrivals, so make sure to be there early!
                        </p>
                        <p className="black-background">
                            Please do not share this event publicly on social media; instead, bring your best friends.
                        </p>
                        <p className="black-background">
                            Show consideration to your fellow guests and surroundings, give each other space to dance, and respect each other’s boundaries.
                        </p>
                        <p className="black-background">
                            We do not tolerate any kind of racism, homophobia, transphobia, ableism, sexism, or other discriminatory behaviors.
                        </p>
                        <p className="black-background">
                            We want everyone to be able to feel safe, seen, acknowledged, and respected. Never hesitate to approach us if you experience something unpleasant or have any questions.
                        </p>
                        <p className="black-background">
                            Come back on the day of the party for the location.
                        </p>
                        <Link to="/location"><button className="understand-button">I understand</button></Link>
                    </div>
                }
            </div>
        )
    }
    else {
        return (
            <div className="centered">
                <p className="cheat">
                    Sorry bud, you are trying to cheat.
                </p>

            </div>
        )
    }
};