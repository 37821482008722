import Cookies from 'universal-cookie';
import { Context } from "../globals/Context";
import AES from "crypto-js/aes";
import { enc } from "crypto-js";
import { useContext, useEffect, useState, useMemo } from "react";

export default function Location() {
    const [isLoading, setIsLoading] = useState(true);
    const { isLoggedIn, setLoggedIn } = useContext(Context);
    const cookies = useMemo(() => new Cookies(), []);  // `cookies` is now memoized

    useEffect(() => {
        const pwc = cookies.get('pwc');
        if (pwc) {
            const bytes = AES.decrypt(pwc, process.env.REACT_APP_SECRET ? process.env.REACT_APP_SECRET : "fail");
            const decryptedPassword = bytes.toString(enc.Utf8);
            if (decryptedPassword === process.env.REACT_APP_PASSWORD) {
                setLoggedIn(true);
            }
        }
        setIsLoading(false);
    }, [cookies, isLoggedIn, setLoggedIn]);


    if (isLoggedIn) {
        return (
            <div className="centered">
                {isLoading && <img src="loading.gif" alt="loading" />}
                {/* This is not the location. But good on you for checking: "https://www.google.com/maps/dir//Vagthuss%C3%B8en,+Amager+Vest,+2300+K%C3%B8benhavn/@55.6468396,12.5517002,15z/data=!4m17!1m7!3m6!1s0x46525519c5c74b97:0xa3b826f100472156!2sVagthuss%C3%B8en!8m2!3d55.6468402!4d12.5697256!16s%2Fg%2F11g1qt7bj5!4m8!1m0!1m5!1m1!1s0x46525519c5c74b97:0xa3b826f100472156!2m2!1d12.5697256!2d55.6468402!3e2?entry=ttu" */}
                {!isLoading && <a href="https://www.google.com/maps/dir//55.7190373,12.6131286/@55.7191647,12.6124625,19.24z/data=!4m2!4m1!3e2?entry=ttu" className="location-link">Take me to the location</a>}
                
            </div>
        )
    }
    else {
        return (
            <div className="centered">
                <p className="cheat">
                    Sorry bud, you are trying to cheat.
                </p>

            </div>
        )
    }
};