// Footer.tsx
import React from 'react';
import '../css/App.css';

const Footer = () => {
  return (
    <div className="footer">
    </div>
  );
}

export default Footer;