import '../css/App.css';
import AES from 'crypto-js/aes';
import { Cookies } from 'react-cookie';
import { useState, useMemo } from 'react';
import { useNavigate } from "react-router-dom";

export default function Home() {
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const navigate = useNavigate();
    const cookies = useMemo(() => new Cookies(), []);  // `cookies` is now memoized

    // Handle change in the input field
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        
        setPassword(e.target.value);
        // Reset error state on new input
        setPasswordError(false);
    };

    // Handle form submission
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const correctPassword = process.env.REACT_APP_PASSWORD ? process.env.REACT_APP_PASSWORD : "";
        if (correctPassword.toLowerCase() === password.toLowerCase()) {
            const secretKey = process.env.REACT_APP_SECRET ? process.env.REACT_APP_SECRET : "default_secret";
            var encryptedPassword = AES.encrypt(password, secretKey).toString();
            cookies.set('pwc', encryptedPassword, { path: '/' });
            navigate("/info");
        } else {
            // Set error state to true if password is incorrect
            setPasswordError(true);
        }
    };

    return (
        <div className="content">
            <img src={passwordError ? "eye2.png" : "eye.gif"} className="App-logo" alt="logo" />
            <form onSubmit={onSubmit}>
                <div>
                    {/* <label htmlFor="pass">Password:</label> */}
                    {passwordError && <p style={{ color: 'red', marginTop: '10px' }}>Wrong password</p>}
                    <input type="password" id="pass" name="password" required onChange={handleChange} />
                </div>
                <p>
                    <button type="submit">Sign in</button>
                </p>
            </form>
        </div>
    );
}
