import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import { Context } from "./globals/Context";
import Footer from './globals/Footer';
import { useEffect, useState } from 'react';
import Info from './pages/Info';
import Location from './pages/Location'

function App() {
  const [isLoggedIn, setLoggedIn] = useState();

  useEffect(() => {
    console.log('App level, is logged in', isLoggedIn)
  }, [isLoggedIn])

  return (
    <div className="background-wrapper">
    <div className="App">
            <Context.Provider value={{isLoggedIn, setLoggedIn}}>
            <BrowserRouter >
        <Routes>
          {/* <Route path="/" element={<Home isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn}/>} />
          <Route path="/info" element={<Info isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn}/>} /> */}
          <Route path="/" element={<Home/>} />
          <Route path="/info" element={<Info/>} />
          <Route path="/location" element={<Location/>} />
           {/* Redirect all other routes */}
           <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
      </Context.Provider>
      <Footer />
    </div>
    </div>
  );
}

export default App;
